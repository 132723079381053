<template>

  <div class="row" ref="winnerForm">

    <div class="col-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                  vid="name"
                  rules="required"
                  name="The Arabic Name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Arabic Name *"
                            name="name"
                            fou
                            v-model="formData.name">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="en_name"
                  rules=""
                  name="The english name"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="english Name"
                            name="en_name"
                            fou
                            v-model="formData.en_name">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                  vid="list"
                  rules=""
                  name="The Arabic List"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Arabic List"
                            name="list"
                            fou
                            v-model="formData.list">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="en_list"
                  rules=""
                  name="The English List"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="English List"
                            name="en_list"
                            fou
                            v-model="formData.en_list">
                  </fg-input>
                </ValidationProvider>
              </div>

            </div>
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                  vid="seat"
                  rules=""
                  name="The Arabic Seat"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Arabic Seat"
                            name="seat"
                            fou
                            v-model="formData.seat">
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="en_seat"
                  rules=""
                  name="The English Seat"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="English Seat"
                            name="en_seat"
                            fou
                            v-model="formData.en_seat">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="voters"
                  rules=""
                  name="The Voters"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="number"
                            :error="failed ? errors[0]: null"
                            label="Voters"
                            name="voters"
                            @keyup="calcPercentage"
                            fou
                            v-model="formData.voters">
                  </fg-input>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="form-group pt-4 ml-3">
                <label>Is Active</label>&nbsp;
                <l-switch v-model="formData.is_active">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>
              </div>
            </div>
            <div class="form-group">
              <label>Image</label>
              <single-file-uploader-file-manager
                :preview-width="'200px'"
                :preview-height="'200px'"
                v-model="formData.image"
                :accepted-files="imagesExtensions">
              </single-file-uploader-file-manager>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;

            <l-button @click="$router.push('/election-winners/list/'+formData.kadaa_id)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import SingleFileUploaderFileManager from "../../../components/FileManager/SingleFileUploaderFileManager";
import FormGroupTextArea, {Switch as LSwitch} from "@/components";
extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    SingleFileUploaderFileManager,
    [Option.name]: Option,
    [Select.name]: Select,
    FgSelect,
    LSwitch,
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        name: "",
        es_name:"",
        fr_name:"",
        en_name:"",
        list: "",
        en_list: "",
        fr_list: "",
        es_list:"",
        seat: "",
        en_seat: "",
        fr_seat: "",
        es_seat: "",
        voters: 0,
        is_active:0,
        total_voters: 0,
        image: "",
        kadaa_id: "",
        percentage: 0,
      },
      imagesExtensions:this.getImagesExtensions(),
      departmentList: [],
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.winnerForm
    });

    this.formData.kadaa_id = this.$route.params['kadaa_id'];
    this.id = this.$route.params['id'];


    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Winner";
      this.getWinner();
    } else {
      this.editMode = false;
      this.formTitle = "Add Winner";
      if (this.formData.kadaa_id !== undefined){
        let data = {kadaa_id: this.formData.kadaa_id};
        this.axios.post("election-winners/builder", data).then((response) => {
          this.formData.total_voters = response.data.kadaa ? response.data.kadaa.voters : 0;
          this.loader.hide();
        }).catch((error) => {
          console.error(error);
        })
      }
      this.loader.hide();
    }
  },


  methods: {

    getWinner() {
      let data = {
          'id' : this.id,
      };
      this.axios.post("election-winners/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Winner Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("election-winners/update/" + this.id, this.formData);
        successMessage = "Winner Updated Successfully";
      } else {
        request = this.axios.post("election-winners/create", this.formData);
        successMessage = "Winner Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/election-winners/list/"+this.formData.kadaa_id);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      });
    },
    calcPercentage(){
      if(this.formData.total_voters && this.formData.total_voters != 0){
        this.formData.percentage =Math.round(this.formData.voters / this.formData.total_voters * 100 * 100) / 100;
      }else{
        this.formData.percentage = 0;
      }
    },
    calcVoters(){
      console.log(this.formData.total_voters)
      console.log(this.formData.percentage)
      this.formData.voters = Math.ceil(this.formData.total_voters * this.formData.percentage / 100);
    },

  }
}
</script>

<style>
</style>
