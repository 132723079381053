var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"winnerForm",staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"name","rules":"required","name":"The Arabic Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Arabic Name *","name":"name","fou":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"en_name","rules":"","name":"The english name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"english Name","name":"en_name","fou":""},model:{value:(_vm.formData.en_name),callback:function ($$v) {_vm.$set(_vm.formData, "en_name", $$v)},expression:"formData.en_name"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"list","rules":"","name":"The Arabic List"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Arabic List","name":"list","fou":""},model:{value:(_vm.formData.list),callback:function ($$v) {_vm.$set(_vm.formData, "list", $$v)},expression:"formData.list"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"en_list","rules":"","name":"The English List"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"English List","name":"en_list","fou":""},model:{value:(_vm.formData.en_list),callback:function ($$v) {_vm.$set(_vm.formData, "en_list", $$v)},expression:"formData.en_list"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"seat","rules":"","name":"The Arabic Seat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Arabic Seat","name":"seat","fou":""},model:{value:(_vm.formData.seat),callback:function ($$v) {_vm.$set(_vm.formData, "seat", $$v)},expression:"formData.seat"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"vid":"en_seat","rules":"","name":"The English Seat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"English Seat","name":"en_seat","fou":""},model:{value:(_vm.formData.en_seat),callback:function ($$v) {_vm.$set(_vm.formData, "en_seat", $$v)},expression:"formData.en_seat"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"voters","rules":"","name":"The Voters"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? errors[0]: null,"label":"Voters","name":"voters","fou":""},on:{"keyup":_vm.calcPercentage},model:{value:(_vm.formData.voters),callback:function ($$v) {_vm.$set(_vm.formData, "voters", $$v)},expression:"formData.voters"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group pt-4 ml-3"},[_c('label',[_vm._v("Is Active")]),_vm._v("  "),_c('l-switch',{model:{value:(_vm.formData.is_active),callback:function ($$v) {_vm.$set(_vm.formData, "is_active", $$v)},expression:"formData.is_active"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Image")]),_c('single-file-uploader-file-manager',{attrs:{"preview-width":'200px',"preview-height":'200px',"accepted-files":_vm.imagesExtensions},model:{value:(_vm.formData.image),callback:function ($$v) {_vm.$set(_vm.formData, "image", $$v)},expression:"formData.image"}})],1)]),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_vm._v("  "),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/election-winners/list/'+_vm.formData.kadaa_id)}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }