<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Winner List {{ kadaa_name ? '( ' + kadaa_name +' )' : '' }}</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['election-winners/create'])"
                       :to="'/election-winners/create/'+kadaa_id"
                       class="btn btn-info btn-wd" wide>
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>

        <div>
          <general-data-table
            ref="table"
            :key="componentKey"
            :api-url="'election-winners/index/'+kadaa_id">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="'right'"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['election-winners/update'])"
                             v-tooltip.top-center="'Edit'"
                             class="btn-warning btn-simple btn-link"
                             :to="'/election-winners/edit/'+scope.row.id">
                  <i class="fa fa-edit"></i>
                </router-link>
                <a v-if="$store.getters['auth/checkAccess']('election-winners/delete')" v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i>
                </a>

              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions']([lang+'/cases/change-active-status']) ||
                                $store.getters['auth/checkAccessOwner']('restricted_own/'+lang+'/cases/change-active-status', props.row.creator_id)"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Winner?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>

    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import FgSelect from "@/components/Inputs/formGroupSelect";


export default {
  components: {
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    FgSelect,
  },
  data() {

    return {
      tableColumns: [
        {label: 'Name', value: 'name', minWidth: '200', align: 'center'},
        {label: 'List', value: 'list', minWidth: '200', align: 'center'},
        {label: 'Seat', value: 'seat', minWidth: '200', align: 'center'},
        {label: 'Voters', value: 'voters', minWidth: '200', align: 'center'},
      ],
      fixedColumn: null,
      deleteModalVisibility: false,
      toDeleteId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL,
      componentKey: 0,
      kadaa_id: undefined,
      kadaa_name: '',
    }

  },
  created() {
    this.kadaa_id = this.$route.params['kadaa_id'];
    let data = {kadaa_id: this.kadaa_id};
    this.axios.post("election-winners/builder", data).then((response) => {
      this.kadaa_name = response.data.kadaa ? response.data.kadaa.name : 0;
    }).catch((error) => {
      console.error(error);
    })
  },
  mounted() {

  },
  methods: {

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
        lang: this.lang,
      }
      this.axios.post("election-winners/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Winner updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {

      })
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("election-winners/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Winner deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
  },
}
</script>
<style>
</style>
